@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();

$light-theme: mat.define-theme((color: (theme-type: light, primary: mat.$azure-palette)));
$dark-theme: mat.define-theme((color: (theme-type: dark, primary: mat.$azure-palette)));

:root {
  --color-1: #134788;
  --color-1-soft: #ebeeff;

  --color-2: #bf2525;
  --color-2-soft: #fdf4f4;

  --color-3: #006156;
  --color-3-soft: #eeffeb;

  --color-4: #836502;
  --color-4-soft: #fffceb;

  --color-5: #6c009e;
  --color-5-soft: #f9ebff;

  --color-6: #9e3a00;
  --color-6-soft: #f9ebff;

  --canva-red: #bf2525;
  --canva-red-light: #ff9494;
  --canva-blue: #134788;
  --canva-blue-light: #93cdfd;
  --canva-yellow: #7a7911;
  --canva-yellow-light: #ffeeaa;
  --canva-green: #0e5748;
  --canva-green-light: #79be97;
  --canva-orange: #572f0e;
  --canva-orange-light: #e4a467;

  --background: #ffffff;
  --background-2: #fafafa;
  --background-3: #e7e7e7;
  --background-gradient: radial-gradient(circle, #f2f2f2 0%, #dadada 50%, #bdbdbd 100%);
  --text-1: #212121;
  --text-2: #757575;
  --text-light: #FFFFFF;
  --text-yellow: #fff8bf;
  --shadow: #7d7d7d;
  --shadow-font: #c7c7c7;
  --inactive-background: #838383;
  --semi-transparent: #ffffffb0;

  --divider-color: #BDBDBD;
  --disabled: #c4c3c3;

  --mat-sidenav-container-width: 10rem !important;
  --font-size-page-title: 2.4rem;
  --font-size-page-subtitle: 1.8rem;
  --padding-page: 4rem 2rem;
  --margin-page-content: 2rem 0;
  --margin-page-title: 0 0 4rem 0;
  --padding-page-subtitle-text: 0.5rem 1.5rem;

  .dark-mode {
    --color-1: #74a8fd;
    --color-2: #fd6969;
    --color-2-soft: #250000;
    --color-3: #28BDAE;
    --color-4: #ddb136;
    --color-5: #D97CFE;
    --color-6: #fea57c;
    --text-1: #ffffff;
    --text-2: #dbdbdb;
    --shadow: #3b3b3b;
    --shadow-font: #3b3b3b;
    --inactive-background: #d7d7d7;
    --background: #141414;
    --background-2: #000000;
    --background-3: #353535;
    --background-gradient: radial-gradient(circle, #4f4f4f 0%, #181818 50%, #000000 100%);
    --disabled: #555555;
    --semi-transparent: #050505b0;
  }
}

html#galerija {
  @include mat.all-component-themes($light-theme);
  --mdc-dialog-container-shape: 0.2rem !important;
  --mdc-filled-button-label-text-size: 1.4rem !important;
  --mat-menu-container-color: white;
  --mat-option-focus-state-layer-color: var(--background-2);
  --mat-option-hover-state-layer-color: var(--background-2);
  --mat-option-label-text-size: 1.4rem !important;
  --mat-option-label-text-line-height: 1.6rem !important;
  --mdc-outlined-button-label-text-size: 1.4rem !important;
  --mdc-outlined-text-field-label-text-size: 1.4rem !important;
  --mat-sidenav-container-background-color: white;
  --mdc-snackbar-supporting-text-size: 1.4rem !important;
  --mat-table-row-item-label-text-size: 1.4rem !important;
  --mdc-text-button-label-text-size: 1.4rem !important;
  --mat-checkbox-label-text-size: 1.4rem !important;

  .dark-mode {
    @include mat.all-component-colors($dark-theme);
  }
}

* {
  outline: none;
}


.color-good {
  color: var(--color-3);
}

.color-bad {
  color: var(--color-2);
}

.gagalin {
  font-family: 'Gagalin', 'Roboto', sans-serif !important;
  letter-spacing: 2px;
}