* {
  box-sizing: border-box;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  padding: 0;
}

html {
  font-size: 62.5%;
  background-color: var(--background);
  height: 100dvh;
  scroll-behavior: smooth;
  margin: 0;
}

body {
  height: 100dvh;
  background-color: var(--background);
  margin: 0;
  overflow-x: hidden;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--color-1);
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem var(--text-2);
}

th,
strong,
textarea,
h1,
h2,
h3,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--text-1);
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

table {
  color: var(--text-1);
}

p {
  font-weight: 400;
  text-align: left;
  color: var(--text-1);
  font-size: 1.4rem;
}

span {
  font-weight: 400;
  display: inline-block;
  color: var(--text-1);
}

div {
  font-size: 1.6rem;

  @media (max-width: 60rem) {
    font-size: 1.4rem;
  }
}

.no-highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

.overlay-switch button.active {
  border-color: var(--color-1);

  span.mdc-button__label {
    font-weight: 500;
    color: var(--color-1);
  }
}