input,
mat-icon,
mat-card.mat-mdc-card {
  color: var(--text-1);
}

mat-card.mat-mdc-card {
  background-color: var(--background) !important;
}

mat-card-title {
  font-size: 1.6rem;
}

button[mat-flat-button],
button[mat-flat-button] span {
  color: var(--background);
  font-weight: 400;
}

mat-form-field {
  margin: 1rem 2rem;

  @media (max-width: 80rem) {
    width: calc(100vw - 6rem);
    margin: 0.5rem 2rem;
  }
}

mat-tab-header {
  .mdc-tab__text-label {
    font-weight: 500;
    font-size: 1.4rem;

    @media (max-width: 340px) {
      font-size: 1.3rem;
    }
  }

  .mat-mdc-tab.mdc-tab--active,
  .mdc-tab--active {
    .mdc-tab__text-label {
      color: var(--color-1);
    }

    &:hover {
      .mdc-tab__text-label {
        color: var(--color-1);
      }
    }
  }
}

mat-card.mat-mdc-card {
  box-shadow: 0.1rem 0.1rem 1rem var(--shadow);
}

mat-card-actions.mdc-card__actions {
  min-height: 4.8rem;
}

.dark-mode table.mat-mdc-table {
  background-color: transparent;
}

mat-snack-bar-container.custom-snackbar {
  position: fixed;
  bottom: 3rem;
  right: 1rem !important;
  max-width: 80vw !important;

  &.info .mdc-snackbar__surface {
    background: var(--color-1);
  }

  &.error .mdc-snackbar__surface {
    background: var(--color-2);
  }

  &.success .mdc-snackbar__surface {
    background: var(--color-3);
  }

  .mdc-snackbar__label {
    color: var(--text-light);
  }
}


div.mat-mdc-menu-panel.mat-mdc-menu-panel {
  border-radius: 0.5rem;

  div.mat-mdc-menu-content {
    padding: 0 !important;
    border: 0.1rem solid var(--divider-color);
  }

  button.mat-mdc-menu-item {
    justify-content: center;
    display: flex;
    align-items: self-end;
    min-height: auto;
    padding: 0.7rem;

  }

  span.mat-mdc-menu-item-text {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: inherit;
    display: inline-block;
  }
}

#table-wrapper {
  label {
    line-height: 3rem;
  }

  .mat-mdc-text-field-wrapper {
    height: 1rem;

    div {
      max-height: 3.6rem;
    }

    .mat-mdc-form-field-infix {
      padding: 0.6rem;
    }

    input {
      font-size: 1.6rem;
    }

  }

  mat-label {
    font-size: 1.3rem;
    position: relative;
    bottom: 1rem;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}

mat-button-toggle-group {
  margin-top: 2rem;
  font-size: 1.3rem;

  mat-button-toggle.mat-button-toggle {
    background-color: var(--background);
  }

  mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: transparent;

    span.mat-button-toggle-label-content {
      color: var(--color-1);
      font-weight: 500;
    }
  }
}

div.cdk-overlay-pane {
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-1);
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.5rem var(--text-2);
  }

  .mat-mdc-dialog-surface {
    background: var(--background);
  }
}

mat-bottom-sheet-container.mat-bottom-sheet-container {
  max-height: 80vh;
}


mat-bottom-sheet-container.mat-bottom-sheet-container-large {
  max-height: 80vh;
  max-width: 80rem;

}

mat-bottom-sheet-container.mat-bottom-sheet-container-xlarge {
  max-height: 70vh;
  max-width: 100rem;
}

div.cdk-overlay-pane.narrow-bottom-sheet mat-bottom-sheet-container.mat-bottom-sheet-container {
  max-width: 50rem;
}

div.cdk-overlay-container div.cdk-overlay-pane.search-dialog-container {
  width: 60rem;
  position: fixed !important;
  top: 10rem;

  @media (orientation: portrait) {
    max-width: 100vw !important;
    top: 0rem;
    z-index: 9999;
    height: 100dvh;
  }
}

div.cdk-overlay-container div.cdk-overlay-pane.social-dialog-container {
  max-width: 100vw !important;
}

div.mat-mdc-tab-labels {
  justify-content: center;
}

div.mat-mdc-autocomplete-panel.mdc-menu-surface {
  max-height: 34rem;
}

div.cdk-overlay-dark-backdrop {
  background: #000000ea;
}

div.mat-mdc-tab-body-content {
  overflow: hidden;
}


div.cdk-overlay-dark-backdrop {
  @media (orientation: portrait) {
    background: rgb(26 26 26 / 92%);
  }
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--color-1) !important;
}

mat-option.mat-mdc-option.mdc-list-item {
  background: var(--background);
}

mat-drawer.mat-drawer-opened {
  border-right: 0.1rem solid var(--divider-color);
  border-radius: 0;
  width: 15rem;
}

div.mat-drawer-inner-container {
  overflow-x: hidden;
}