#table-wrapper {
  margin: 2rem;
}

td {
  vertical-align: top;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1 1 auto;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.page-wrapper {
  position: relative;
  min-height: 100dvh;
  padding: var(--padding-page);
  margin: 0 auto;
  text-align: left;

  h1 {
    margin: var(--margin-page-title);
    font-size: var(--font-size-page-title);
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
}

.page-subtitle-row {
  display: block;
  text-transform: uppercase;
  margin: var(--margin-page-content);
  padding: 0;

  h5.page-subtitle-text {
    font-weight: 500;
    font-size: var(--font-size-page-subtitle);
    display: inline-block;
    border-top: 0.2rem dashed var(--color-2);
    border-bottom: 0.2rem dashed var(--color-1);
    padding: var(--padding-page-subtitle-text);
  }
}

.single-item {
  margin: 0 auto 2rem auto;

  @media (min-width: 600px) {
    margin: 0 2rem 2rem 0;
  }
}

button.add-button {
  margin-bottom: 1rem;

  @media (max-width: 1000px) {
    width: 100%;
  }
}

button.shortcut-button {
  margin-right: 2rem;
  margin-bottom: 1rem;
}

button#save {
  margin: 0 2rem;

  @media (max-width: 100rem) {
    width: calc(100vw - 6rem);
  }

  @media (max-width: 50rem) {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    height: 5.5rem !important;
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0rem;
    z-index: 999;
    border-radius: 0;
  }
}

button#delete {
  background-color: var(--color-2);
}

[class$="-wrapper"]:not(.cdk-global-overlay-wrapper) {
  background-color: var(--background);
}

.margin {
  margin: 1.6rem;
}

.margin-vertical {
  margin: 1.6rem 0;
}

.margin-horizontal {
  margin: 0 1.6rem;
}

.padding {
  padding: 1.6rem;
}

.padding-vertical {
  padding: 1.6rem 0;
}

.padding-horizontal {
  padding: 0 1.6rem;
}

.quill-wrapper {
  width: 100%;
}

quill-editor {
  width: 100%;
  padding: 2rem;
}

.inner-html {
  color: var(--text-2);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 1.4rem;
  display: inline-block;

  p {
    color: var(--text-2);
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  strong {
    font-size: 1.4rem;
    color: var(--text-1);
    font-weight: 500;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  a {
    text-decoration: none;
    color: var(--color-5);

    &:hover {
      color: var(--color-3);
    }
  }
}

.show-over-1200 {
  @media (max-width: 120rem) {
    display: none !important;
  }
}

.show-under-1200 {
  @media (min-width: 120rem) {
    display: none !important;
  }
}

.portrait-screen-only {
  @media (orientation: landscape) {
    display: none !important;
  }
}

.landscape-screen-only {
  @media (orientation: portrait) {
    display: none !important;
  }
}

@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.auto-counter {
  transition: --num 150ms;
  counter-reset: num var(--num);

  &::after {
    content: counter(num);
  }
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.multiline-shorter {
  -webkit-line-clamp: 2;
}

button.mat-mdc-icon-button.add-button-large {
  position: fixed;
  bottom: 7rem;
  right: 3rem;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
  z-index: 99;
  border: 0.2rem solid var(--color-1);
  background-color: var(--background);
  padding: 0.7rem 0;

  mat-icon {
    height: 3rem;
    width: 3rem;
    font-size: 3rem;
    cursor: pointer;
    color: var(--color-1);
  }

  &.second {
    bottom: 13rem;
    border: 0.2rem solid var(--color-3);

    mat-icon {
      color: var(--color-3);
    }
  }

  &.third {
    bottom: 19rem;
    border: 0.2rem solid var(--color-4);

    mat-icon {
      color: var(--color-4);
    }
  }

  &.fourth {
    bottom: 25rem;
    border: 0.2rem solid var(--color-5);

    mat-icon {
      color: var(--color-5);
    }
  }

  &.fifth {
    bottom: 31rem;
    border: 0.2rem solid var(--color-6);

    mat-icon {
      color: var(--color-6);
    }
  }

  &:hover {
    background: var(--background-2);
  }
}

.danger {
  color: var(--color-2);
  font-size: 1.2rem;
}

.game-quiz-title {
  color: var(--color-1);
  font-size: 1.7rem;
  font-weight: 500;
}

.game-quiz-subtitle {
  color: var(--color-2);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
}

.game-quiz-mission {
  color: var(--text-1);
  font-size: 1.5rem;
  line-height: 2rem;
}

.game-score {
  margin: 0 3rem 1rem;
  user-select: none;

  b {
    font-weight: 500;
    color: var(--color-3);
  }
}

.game-footer-block {
  text-align: center;
}

button.mdc-button.game-footer-button {
  background: var(--color-1);
  color: var(--background);
  margin: 0 auto 2rem auto;

  &.success {
    background: var(--color-3);
  }
}

button:disabled {
  border-color: var(--disabled) !important;
  color: var(--disabled) !important;
  cursor: default;

  span {
    color: var(--disabled) !important;
  }
}

.game-item {
  display: flex;
  cursor: grab;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;

  &.correct-answer * {
    color: var(--color-3) !important;
  }

  &.wrong-answer * {
    color: var(--color-2) !important;
    padding: 0;

    &.mat-mdc-form-field-type-mat-select div.mdc-text-field {
      padding-left: 1.6rem;
    }

    &.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
      padding-left: 1.6rem;
    }
  }

  div.question {
    color: var(--text-1) !important;
  }

  .mat-mdc-form-field-hint-wrapper mat-hint.mat-mdc-form-field-hint {
    font-size: 1.3rem;
    color: var(--color-4) !important;
    left: -1rem;
  }

  mat-form-field.game-input {
    width: 24rem;
  }

  p.item-title,
  div.question,
  div.game-item-name,
  div.game-five-content * {
    color: var(--text-1) !important;
  }
}

.drag-drop>* {
  touch-action: none;
}

.basic-sheet-overlay {
  mat-bottom-sheet-container.mat-bottom-sheet-container {
    min-height: unset;
    max-height: unset;
    min-width: unset;
    max-width: unset;
    width: min(100vw, 100rem);
    max-height: 80vh;
  }
}

.sheet-inner-html a {
  color: var(--color-4);
  text-decoration: none;
}

.image-shadow-filter {
  filter: drop-shadow(0.1rem 0 15px var(--shadow));
}

.quiz-name-box {
  background: var(--background);
  border: 0.1rem dashed var(--text-2);
  border-radius: 0.8rem;
}

.scroll-item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  white-space: normal;
  user-select: none;
  cursor: pointer;

  margin-right: 0;
  margin-block: 0;
  border-width: 0.2rem;
  border-radius: 0.5rem;
  border-style: solid;
  border-color: var(--shadow);

  &:hover {
    border-color: var(--text-1);
  }
}

.pan-vertical {
  touch-action: pan-y !important;
  padding-top: 0.2rem;
}

.story-spacing {
  position: absolute;
  width: 100%;
  top: 0;
  height: 6rem;
  background: var(--canva-blue);
}

app-horizontal-scroll .single-item {
  touch-action: auto !important;
}

.dialog-footer {
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0;
  margin-inline: 3rem;
  width: calc(100% - 6rem);
  border-top: 0.25rem solid var(--color-2);
  color: var(--color-1);
  font-weight: 500;
}

.print-html {
  padding: 0.5rem 2rem;
  width: 100%;

  p {
    display: none;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.6rem;
  }

  span {
    display: inline;
  }

  em {
    font-style: normal;
    color: var(--color-2);
    font-weight: bold;
  }

  strong {
    display: block;
    text-align: center;
    width: 100%;
    color: var(--canva-blue);
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
  }

  p:nth-of-type(1),
  p:nth-of-type(2) {
    display: block;
  }
}

h1.page-title {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
  left: -2rem;

  span {
    font-weight: 500;
    border-radius: 0;
    border: 0;
    font-size: 2.6rem;
    font-family: gagalin;
    letter-spacing: 2px;
    padding: 1.5rem 10rem 1.5rem 3rem;
    text-align: left;
    color: white;
    background: linear-gradient(90deg,
        var(--canva-blue) 0%,
        var(--canva-blue) 60%,
        transparent 100%,
      );
  }
}

.template-wrapper {
  padding: 1rem;

  h1.tag-name {
    color: var(--color-1);
    text-transform: uppercase;
    font-size: 2rem;
    margin-top: 1rem;
  }

  p.tag-type {
    margin-block: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  div.tag-description {
    display: pre;
    text-align: justify;
    line-height: 1.8rem;
    font-size: 1.4rem;
    overflow-y: auto;
    padding-right: 1rem;

    @media (orientation: portrait) {
      text-align: left;
    }
  }

  div.city-data {
    justify-content: flex-start;
    align-items: flex-start;
  }

  div.basic-data {
    border: 0;
    margin: 0;
    padding: 0;
  }

  div.basic-data-row {
    margin-inline: 0;
  }

  div.serbian-map-container {
    margin-inline: 0;
  }
}


.minimize-data-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.minimize-data-list .minimize-data-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  &:hover {
    .data-text {
      span {
        color: var(--color-4);
      }
    }
  }
}

.minimize-data-list img.data-image {
  width: 3rem;
  border-radius: 0.6rem;
  color: var(--color-1);
}

.minimize-data-list mat-icon.data-image {
  transform: scale(1.1);
  color: var(--color-1);
}

.minimize-data-list .data-text {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.5rem);

  span {
    font-weight: 500;
  }

  p {
    color: var(--text-2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 1rem);
  }
}

.first-word {
  color: var(--color-1);
  font-weight: 500;

  &.red {
    color: var(--canva-red);
  }
}

.single-item.card {
  margin-top: 0;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 0.2rem solid transparent;

  &:hover {
    border: 0.2rem solid var(--divider-color);
  }

  @media (orientation: portrait) and (max-width: 500px) {
    margin-right: 0;
  }
}

.card-title {
  color: var(--color-1);
  font-family: 'Gagalin', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: var(--color-1);
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  font-size: 1.8rem;
}

.card-subtitle {
  color: var(--color-4);
  font-family: 'Gagalin', sans-serif;
  letter-spacing: 2px;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}